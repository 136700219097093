import MoAuth from '@mo-platform/auth'

const auth = new MoAuth({
  "loginUrl": "https://auth-proxy.edtech.fagbokforlaget.no/auth/eportaloidc/edtech/login",
  "logoutUrl": `https://auth-proxy.edtech.fagbokforlaget.no/auth/eportaloidc/edtech/logout?redirect_url=${encodeURIComponent(window.location.origin)}`,
  "userFetchUrl": "https://auth-proxy.edtech.fagbokforlaget.no/auth/eportaloidc/edtech/userinfo",
  "accessCheckUrl": "https://auth-proxy.edtech.fagbokforlaget.no/auth/eportaloidc/edtech/roles"
})

export default auth
