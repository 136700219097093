import { markdownToHtml } from "../utils"
import Tablesaw from 'tablesaw'

const mdHtml = {
  bind: function (el, binding) {
    el.innerHTML = markdownToHtml(binding.value)
  },

  update: function (el, binding) {
    el.innerHTML = markdownToHtml(binding.value)

    const tables = Array.from(el.querySelectorAll('table'))
    if (tables.length) {
      tables.forEach(t => {
        t.classList.add('tablesaw')
        t.dataset.tablesawMode = 'stack'
      })
      Tablesaw.init()
    }
  }
}

const pin = {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
}

export {
  pin,
  mdHtml
}