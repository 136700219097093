const truncate = (value, num = 21, type = 'string') => {
  let valueToTruncate = value

  if (type === 'word') {
    valueToTruncate = valueToTruncate.split(' ')
  }

  if (valueToTruncate.length > num) {
    let trunc

    if (type === 'word') {
      trunc = valueToTruncate.splice(0, num).join(' ')
    } else {
      trunc = valueToTruncate.slice(0, num)
    }

    return `${trunc}...`
  }

  return value
}

export {
  truncate
}
