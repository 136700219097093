import Tenant from '@/services/tenants'
import configData from '@/config'
import { hasLocales, getLocales, setLocales } from '@/services/locales'
import i18n from '@/i18n'

const setupTenant = async ({ commit, dispatch }) => {
  const config = await configData()

  if (!config) throw Error('Tenant not found.')

  const tenant = () => new Tenant(config)
  tenant()
  commit('SET_CONFIG', config)
  dispatch('initLocale')
}

const initLocale = async ({ commit }) => {
  const config = await configData()

  if (!config.langs) return
  
  if (hasLocales()) {
    const currentLocales = getLocales()

    commit('SET_SLUG', currentLocales.slug)
    commit('SET_CURRENT_LOCALE', currentLocales)
    i18n.locale = currentLocales.code
  } else {
    commit('SET_CURRENT_LOCALE', config.langs[0])
  }
}

const setLocale = ({ commit }, locales) => {
  commit('SET_SLUG', locales.slug)
  commit('SET_CURRENT_LOCALE', locales)
  i18n.locale = locales.code
  setLocales(locales)
  window.location = window.location.origin
}

const clearTenant = ({ commit }) => {
  commit('CLEAR_CONFIG')
}

const setUserRole = ({ state, commit }, role) => {
  const lastRole = state.userRole
  const wrapper = document.querySelector('#app')

  commit('SET_USER_ROLE', role)
  wrapper.classList.remove(lastRole)
  wrapper.classList.add(role)
}

const toggleCheckedAccess = ({ commit }, checked) => {
  commit('TOGGLE_CHECKED_ACCESS', checked)
}

export default {
  clearTenant,
  setupTenant,
  setUserRole,
  setLocale,
  initLocale,
  toggleCheckedAccess
}
