import auth from '@/auth'
import store from '@/store'
import router from '@/router'

class LoginBase {
  constructor() {
    this.auth = auth
    this.store = store
    this.router = router
  }

  routeHandler(/* { to, from, next } */) {
    // 
  }

  handleStudent() {}

  handleTeacher() {}
}

export default LoginBase
