const SET_CONFIG = (state, config) => {
  const { addons, access, teacherAddons, mainNav, concepts, hasRoleSwitch, login, text, name, id, slug, assets, layout, level, levels, gtm, beta, meta, langs, dbok, inactive, dbokLink } = config

  state.name = name
  state.slug = slug
  state.id = id
  state.assets = assets
  state.layout = layout
  state.levels = levels || []
  state.level = level || null
  state.addons = addons || null
  state.teacherAddons = teacherAddons || null
  state.gtm = gtm || null
  state.beta = beta || null
  state.meta = meta || []
  state.langs = langs || []
  state.login = login || null
  state.text = text || null
  state.hasRoleSwitch = hasRoleSwitch || false
  state.mainNav = mainNav || null
  state.conceptsSettings = concepts || null
  state.access = access || null
  state.dbok = dbok || null
  state.inactive = inactive || null
  state.dbokLink = dbokLink || null
}

const SET_SLUG = (state, slug) => {
  state.slug = slug
}

const SET_CURRENT_LOCALE = (state, locale) => {
  state.currentLocale = locale
}

const CLEAR_CONFIG = state => {
  state.name = ''
  state.slug = ''
  state.id = ''
  state.assets = null
  state.levels = []
  state.level = null
  state.addons = null
  state.gtm = ''
  state.beta = null
  state.langs = []
  state.currentLocale = null
  state.login = null
  state.text = null
  state.hasRoleSwitch = false
  state.mainNav = null
  state.conceptsSettings = null
  state.access = null
  state.dbok = null
  state.dbokLink = null
}

const SET_USER_ROLE = (state, role) => {
  state.userRole = role
}

const TOGGLE_CHECKED_ACCESS = (state, checked) => {
  state.routerCheckedAccess = checked  
}

export default {
  CLEAR_CONFIG,
  SET_CONFIG,
  SET_USER_ROLE,
  SET_SLUG,
  SET_CURRENT_LOCALE,
  TOGGLE_CHECKED_ACCESS
}
