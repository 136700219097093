<template>
  <components :is="currentTopBar" />
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

const DEFAULT_TOPBAR = 'TopBarDefault'

export default {

  setup(props, ctx) {
    const appLayoutTopBar = computed(() => ctx.root.$store.getters['appConfig/getAppLayoutTopBar'] || DEFAULT_TOPBAR)
    const currentTopBar = ref(null)


    watch(appLayoutTopBar, layout => {
      currentTopBar.value = () => import(`./TopBar/${layout}`)
    })

    return {
      appLayoutTopBar,
      currentTopBar,
    }
  }
}
</script>
