class Tenant {
  constructor(tenant = null) {
    this.tenant = tenant
    this.setup()
  }

  setup() {
    this.setTitle()
    this.setFavicon()
    this.addWrapperClass()
    this.loadCSS()
  }

  setTitle() {
    document.title = this.tenant.name
  }

  addWrapperClass() {
    document.querySelector('#app').classList.add(this.tenant.slug)
  }

  setFavicon() {
    if (!this.tenant.assets && !this.tenant.assets.favicon) return

    let favicon = Array.from(document.querySelectorAll('link[rel=icon]'))
    
    if (!favicon.length) {
        favicon = document.createElement('link')
        favicon.rel = 'icon'
        document.head.appendChild(favicon)
    }

    favicon.forEach(icon => {
      icon.type = 'image/png'
      icon.href = this.tenant.assets.favicon
    })
  }

  loadCSS() {
    this.tenant.styles.forEach(style => {
      const link = document.createElement('link')

      link.rel = 'stylesheet'
      link.type = 'text/css'
      link.href = style
  
      document.head.appendChild(link)
    })
  }
}

export default Tenant
