import Vue from 'vue'
import VueRouter from 'vue-router'

import configData from '@/config'
import loginStrategy from '@/services/loginStrategy'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Overview',
    component: async () => {
      const DEFAULT_HOME_COMPONENT = 'Overview'
      const cd = await configData()
      const homeComponent =
        (cd.homeComponent && cd.homeComponent.component) ||
        DEFAULT_HOME_COMPONENT

      return import(`@/views/${homeComponent}`)
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/plan',
    name: 'Plan',
    component: () => import(`@/views/Plan`),
    meta: {
      requiresAuth: true,
    },
  },
  // to be removed
  {
    path: '/choose-level',
    name: 'ChooseLevel',
    component: () => import('@/views/ChooseLevel'),
  },
  {
    path: '/unit/:slug',
    name: 'Unit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Unit" */ '../views/Unit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/unit/:pslug/:slug',
    name: 'SubUnit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Unit" */ '../views/Unit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/plan/:slug',
    name: 'PlanUnit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Unit" */ '../views/PlanUnit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/plan/lesson/:slug?',
    name: 'PlanLesson',
    component: () =>
      import(/* webpackChunkName: "Lesson" */ '../views/PlanLesson.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/lesson/:slug',
    name: 'Lesson',
    component: () =>
      import(/* webpackChunkName: "Lesson" */ '../views/Lesson.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/fagstoff/:slug?',
    name: 'TeachersUnit',
    component: () =>
      import(/* webpackChunkName: "TeacherUnit" */ '../views/TeacherUnit.vue'),
    meta: {
      requiresAuth: true,
      hideSwitch: true,
    },
  },
  {
    path: '/fagstoff/:lesson/:article',
    name: 'TeachersArticle',
    component: () =>
      import(
        /* webpackChunkName: "TeachersArticle" */ '../views/TeachersArticle.vue'
      ),
    meta: {
      requiresAuth: true,
      hideSwitch: true,
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: () =>
      import(/* webpackChunkName: "Search" */ '../views/Search.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/present',
    name: 'Presentation',
    component: () =>
      import(/* webpackChunkName: "Presentation" */ '../views/Present.vue'),
    meta: {
      requiresAuth: true,
      hideHeader: true,
    },
  },
  {
    path: '/exercises/:lesson/:id/:exercise?',
    name: 'Exercise Carousel',
    component: () => import('../views/Exercise.vue'),
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideSwitch: true,
    },
  },
  {
    path: '/images/:lesson/:id',
    name: 'Image Carousel',
    component: () =>
      import(/* webpackChunkName: "Carousel" */ '../views/Carousel.vue'),
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideSwitch: true,
    },
  },
  {
    path: '/concepts/:unitSlug',
    name: 'Concepts Search',
    component: () => import('../views/ConceptsSearch.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/content/:lesson/:id/:exercise?',
    name: 'Content Carousel',
    component: () =>
      import(/* webpackChunkName: "Exercise" */ '../views/Exercise.vue'),
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideSwitch: true,
    },
  },
  {
    path: '/video/:lesson/:id',
    name: 'Video',
    component: () => import('../views/Exercise.vue'),
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideSwitch: true,
    },
  },
  {
    path: '/audio/:lesson/:id',
    name: 'Audio',
    component: () => import('../views/LE.vue'),
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideSwitch: true,
    },
  },
  {
    path: '/image/:lesson/:id',
    name: 'ImageElement',
    component: () => import(/* webpackChunkName: "Media" */ '../views/LE.vue'),
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideSwitch: true,
    },
  },
  {
    path: '/article/:lesson/:id',
    name: 'Article',
    component: () => import(/* webpackChunkName: "Media" */ '../views/LE.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/exercise/:lesson/:id',
    name: 'Exercise',
    component: () =>
      import(/* webpackChunkName: "Media" */ '../views/Exercise.vue'),
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideSwitch: true,
    },
  },
  {
    path: '/clickable-area/:lesson/:id',
    name: 'ClickableArea',
    component: () => import('../views/LE.vue'),
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideSwitch: true,
    },
  },
  {
    path: '/activity/:lesson/:id',
    name: 'Activity',
    component: () => import('../views/LE.vue'),
    meta: {
      requiresAuth: true,
      hideHeader: true,
    },
  },
  {
    path: '/embed/:lesson/:id/:page?',
    name: 'EmbedExercise',
    component: () => import('../views/LE.vue'),
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideSwitch: true,
    },
  },
  {
    path: '/concretes/:slug',
    name: 'Concretes',
    component: () =>
      import(/* webpackChunkName: "Unit" */ '../views/Concretes.vue'),
    meta: {
      requiresAuth: true,
      hideSwitch: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () =>
      import(/* webpackChunkName: "Logout" */ '../views/Logout.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  document.body.setAttribute('tabindex', '-1')
  document.body.focus()
  document.body.removeAttribute('tabindex')

  const config = await configData()

  if (config.login && to.matched.some(record => record.meta.requiresAuth)) {
    const ls = loginStrategy(config.login.type)

    ls.do('routeHandler', { to, from, next, config })
  } else {
    next()
  }
})

export default router
