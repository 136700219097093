const hasUser = state => {
  return state.user !== null
}

const getToken = state => state.accessToken

export default {
  hasUser,
  getToken,
}
