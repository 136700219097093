import LoginBundle from './login/bundle'
import LoginTeacher from './login/teacher'
import LoginGuardTeacherOnly from './login/guardTeacherOnly'
import LoginStudentAndTeacher from './login/studentAndTeacher'

class LoginStrategy {
  constructor(type = '') {
    this._types = {
      bundle: LoginBundle,
      teacher: LoginTeacher,
      guardTeacherOnly: LoginGuardTeacherOnly,
      studentAndTeacher: LoginStudentAndTeacher
    }

    if (!type) {
      throw new Error("Can't instantiate. Missing strategy type.")
    }

    if (!this._types[type]) {
      throw new Error("Can't instantiate. Missing strategy type.")
    }

    this.strategy = null
    this.type = type
    this.setStrategy()
  }

  setStrategy() {
    this.strategy = this._types[this.type]()
  }

  do(action, props) {
    this.strategy[action](props)
  }
}

export default type => new LoginStrategy(type)
