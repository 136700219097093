const DEFAULT_HEADERS = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + process.env.VUE_APP_COSS_TOKEN,
  }
}

const fetchWithAuth = (path, options) => {
  const mergedOptions = Object.assign({}, DEFAULT_HEADERS, options)

  return fetch(`${process.env.VUE_APP_COSS_URL}/${path}`, mergedOptions)
}

export {
  fetchWithAuth
}
