<template>
  <div id="app" :class="{ 'has-inactive-overlay': inactiveOverlay }">
    <Topbar v-if="!$route.meta.hideHeader" />
    <SwitchRole v-if="hasRoleSwitch && !$route.meta.hideSwitch" />
    <router-view :key="$route.fullPath" />
    <Footer />
  </div>
</template>

<script>
import Vue from 'vue'
import { computed } from '@vue/composition-api'
import { mapActions, mapGetters, mapState } from 'vuex'
import VueGtm from '@gtm-support/vue2-gtm'
import Topbar from '@/components/Topbar'
import Footer from '@/components/Footer'
import SwitchRole from '@/components/SwitchRole'

import auth from '@/auth'

export default {
  components: {
    Topbar,
    SwitchRole,
    Footer,
  },

  beforeMount() {
    this.setupTenant()
  },

  computed: {
    ...mapState('appConfig', {
      hasRoleSwitch: state => state.hasRoleSwitch,
    }),

    ...mapGetters('appConfig', [
      'getGTM',
      'getMeta',
      'getTeacherAccessID',
      'hasTeacherAccess',
    ]),
  },

  methods: {
    ...mapActions({
      setupTenant: 'appConfig/setupTenant',
      setUser: 'user/setUser',
    }),


    setRole(r) {
      const role = r || this.$store.state.userRole || localStorage.getItem('role')

      localStorage.setItem('role', role)
      this.store.dispatch('appConfig/setUserRole', role)
    }
  },

  metaInfo() {
    return {
      meta: this.getMeta,
    }
  },

  mounted() {
    auth
      .checkToken(
        this.$route.query.token ? `?token=${this.$route.query.token}` : '',
      )
      .then(user => {
        this.setRole()
        if (this.$route.query.token) {
          this.$router.push({ query: { token: undefined } })
        }
        this.$store.dispatch('user/setToken')
        this.$store.dispatch('user/setUser', user)
        if (this.hasTeacherAccess) {
          auth.checkAccess(this.getTeacherAccessID).then(res => {
            if (
              res.products.some(
                product => product.id === this.getTeacherAccessID,
              )
            ) {
              this.setRole('teacher')
            }
          })
        }
      })
      .catch(() => {
        if (/role=teacher/g.test(window.location.href)) {
          auth.authorize({
            redirectUrl: window.location.origin,
          })
        }
      })

    if (/teacher=true/g.test(window.location.href)) {
      this.setRole('teacher')
      this.setUser({
        name: 'Teacher',
      })
    }
  },

  watch: {
    getGTM(val) {
      if (!val) return
      Vue.use(VueGtm, {
        id: val,
      })
    },
  },

  setup(props, ctx) {
    const inactiveOverlay = computed(
      () => ctx.root.$store.getters['appConfig/getInactiveOverlay'],
    )

    return {
      inactiveOverlay,
    }
  },
}
</script>
