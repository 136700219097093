import { fetchWithAuth } from '@/api'

// Shorthand
const fetchSH = async param => (await fetchWithAuth(param)).json()

const fetchCourseBySlug = async courseSlug => {
  const data = await fetchSH('courses/' + courseSlug)

  return data
}

const fetchCourseUnits = courseSlug => fetchSH('courses/' + courseSlug + '/units')

const fetchCourseAllVideoType = courseSlug => fetchSH('courses/' + courseSlug + '/videotype')

const fetchCourseAllAudioType = courseSlug => fetchSH('courses/' + courseSlug + '/audiotype')

const fetchCourseAllArticleType = courseSlug => fetchSH('courses/' + courseSlug + '/articletype')

const fetchCourseAllComponent = (courseSlug, componentName) => fetchSH('courses/' + courseSlug + '/component/' + componentName)

const fetchUnitBySlug = async unitSlug => {
  const data = await fetchSH('units/' + unitSlug)

  return data
}

const fetchUnitLessons = async unitSlug => fetchSH('units/' + unitSlug + '/lessons')


const fetchLesson = async lessonSlug => {
  const data = await fetchSH('lessons/' + lessonSlug)

  return data
}

const fetchLessonUnit = unitId => fetchSH('units/' + unitId)

export {
  fetchCourseBySlug,
  fetchCourseUnits,
  fetchUnitBySlug,
  fetchUnitLessons,
  fetchLesson,
  fetchLessonUnit,
  fetchCourseAllVideoType,
  fetchCourseAllAudioType,
  fetchCourseAllArticleType,
  fetchCourseAllComponent
}
