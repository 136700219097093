import { fetchCourseBySlug, fetchCourseUnits, fetchUnitBySlug, fetchLesson } from '@/services/strapi'

const setCourse = async ({ rootState, state, commit }) => {
  if (state.course) return

  const course = await fetchCourseBySlug(rootState.appConfig.slug)

  commit('SET_COURSE', course)

  return course
}

const setUnits = async ({ rootState, state, commit }) => {
  if (state.units.length > 0) return

  const units = await fetchCourseUnits(rootState.appConfig.slug)

  commit('SET_UNITS', units)

  return units
}

const setUnit = async ({ state, commit }, slug) => {
  if (state.units.filter(u => u.slug === slug).length > 0) {
    return
  }

  const unit = await fetchUnitBySlug(slug)

  commit('SET_UNIT', unit)

  return unit
}

const setLesson = async ({state, commit}, slug) => {
  if (state.lessons.filter(l => l.slug === slug).length > 0) return

  const lesson = await fetchLesson(slug)

  commit('SET_LESSON', lesson)

  return lesson
}

const setLessons = ({commit, getters}, lessons) => {
  lessons.forEach(l => {
    if (getters['getLessonById'](l.id)) return

    commit('SET_LESSON', l)
  })
}

export default {
  setCourse,
  setUnits,
  setUnit,
  setLesson,
  setLessons
}
