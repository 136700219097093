import LoginBase from './base'
class LoginStudentAndTeacher extends LoginBase {
  constructor() {
    super()
  }

  routeHandler({ to, next }) {
    if (!this.store.getters['appConfig/hasCheckedAccess']) {
      this.auth.checkToken(to.query.token ? `?token=${to.query.token}` : '')
        .then(user => {
          if (to.query.token) {
            delete to.query.token
          }
          /* remove when we move to checkAccess */
          this.store.dispatch('user/setToken')
          this.store.dispatch('user/setUser', user)
          this.store.dispatch('appConfig/toggleCheckedAccess', true)

          next({ path: to.path, query: to.query })
        })
        .catch(() => {
          next({path: '/login'})
        })
    } else {
      next()
    }
  }

  handleStudent() {
    this.authorize('student')
  }

  handleTeacher() {
    this.authorize('teacher')
  }

  setRole() {
    const ls = localStorage.getItem('role')
    const role = this.store.state.userRole || ls

    if (!ls) localStorage.setItem('role', role)
    this.store.dispatch('appConfig/setUserRole', role)
  }

  authorize(role) {
    this.auth.authorize({
      'redirectUrl': window.location.origin + `?role=${role}`
    })
  }
}

export default () => new LoginStudentAndTeacher()
