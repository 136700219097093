/* LocalStorage service for saving locale settings */

const OBJECT_KEY = 'locales'

const hasLocales = () => {
  return localStorage.getItem(OBJECT_KEY) !== null
}

const getLocales = () => {
  return JSON.parse(localStorage.getItem(OBJECT_KEY))
}

const setLocales = locale => {
  localStorage.setItem(OBJECT_KEY, JSON.stringify(locale))
}

const clearLocales = () => {
  localStorage.clear(OBJECT_KEY)
}

export {
  hasLocales,
  getLocales,
  setLocales,
  clearLocales
}
