const nn = {
  ALL_RESOURCES_FILTER: 'Vis',
  LANGUAGE_CODE: 'nn_NO',
  LANGUAGE_NAME: 'Nynorsk',
  LYRICS: 'Lyrics',
  NAV_PLANNING: 'Oversikt',
  NAV_PEDAGOGY: 'Fagstoff',
  NAV_ASSESSMENT: 'Vurdering',
  NAV_RESOURCES: 'Ressurser',
  NAV_FIND_SUBJECT: 'Finn Fagstoff',
  NAV_OVERVIEW: 'Oversikt',
  NAV_DBOK: 'D-bok',
  NAV_CONCRETES: 'Konkreter',
  NAV_GIVE_FEEDBACK: 'BETA <span>– GI TILBAKEMELDING</span>',
  MAIN_NAVIGATION: 'Hovednavigasjon',
  SEARCH: 'Søk',
  SEARCH_PLACEHOLDER: 'Skriv her…',
  SEARCH_FORM: 'Søk form',
  SEARCH_INPUT: 'Search field',
  OVERVIEW_HI: 'Hei',
  OVERVIEW_TITLE: 'Hva har du lyst til å gjøre?',
  VIEW_OVERVIEW_LINGUISTIC_STRUCTURES: 'Språklige strukturer',
  VIEW_OVERVIEW_WORD: 'Ord',
  VIEW_OVERVIEW_SOUNDS: 'Lyder/uttale',
  VIEW_OVERVIEW_PHRASES: 'Phrases',
  OVERVIEW: 'Oversikt',
  OVERVIEW_THEMES_OVERVIEW: 'Velkommen',
  OVERVIEW_TEACHING_PROGRAM: 'Undervisningopplegg',
  OVERVIEW_LESSON_RESOURCES_ASSESSMENT: 'Ressurser',
  OVERVIEW_TEACHER_UNITS: 'Fagstoff',
  OVERVIEW_READ_MORE: 'Les mer',
  OVERVIEW_COMING: 'Kommende',
  ALL_RESOURCES: 'Alle ressurser',
  UNIT_INTERCULTURAL_COMPETENCE: 'Intercultural competence',
  UNIT_THEME_GOAL: 'Temamål',
  UNIT_LEARNING_OBJECTIVES: 'Learning objectives',
  UNIT_FOCUS_POINTS: 'Fokus på',
  UNIT_LANGUAGES_AND_THEMES: 'Language and theme',
  UNIT_START: 'Start &gt;',
  UNIT_HEADER_INFO_BTN: 'Om kapittelet',
  UNIT_NAV_GO_TO: 'Gå til',
  GOALS_MODAL_CLOSE: 'Lukk',
  TABLIST_UNIT_LABEL: 'Innholdsnavigering',
  BLOG_CATEGORIES: 'Kategorier',
  BLOG_ARTICLE_TOOLBAR:
    'Undervisningsopplegg og oppgåver der strategien er nyttig',
  LANGUAGE_SELECT_LABEL: 'Språk',
  LESSONS_PROOF_OF_LEARNING: 'BEVIS FOR LÆRING',
  LESSONS_GOALS: 'Mål',
  LESSONS_ASSESMENT_ACTIVITITES: 'Vurderingsaktivitet',
  LESSONS_SUGGESTIONS_FOR_TEACHING_SCHEDULE: 'FORSLAG TIL UNDERVISNINGSOPPLEGG',
  LESSONS_EDIT_IN_ONENOTE: 'Rediger i OneNote',
  LESSONS_DOWNLOAD_WORD: 'Last ned som Word',
  LESSONS_EDIT: 'Redigere',
  LESSONS_STUDENT_VIEW: 'Studentvisning',
  LESSONS_VIEW_MORE: 'Sjå meir',
  LESSON_TOOLBAR_PRESENT: 'Presenter',
  LESSONS_EXTRA_ACTIVITIES: 'EKSTRA AKTIVITET',
  LESSONS_FOR_TEACHERS: 'Til læraren',
  LESSON_GOALS_BTN_TEXT: 'Læringsmål, vurdering og strategier',
  GO_TO_LESSON_PLAN: 'Gå til undervisningsopplegg',
  LESSON_SECTION_LINK: 'Vis opplegg',
  USER_LOGOUT: 'Logg ut',
  USER_HAS_BEEN_LOG_OUT: 'You have been logged out.',
  BREADCRUMBS_UNIT: 'Thema ',
  BREADCRUMBS_LESSON: 'Leksjon ',
  FOOTER_STUDENT_VIEW: 'Elev-visning',
  FOOTER_BACK_TO_TEACHER: 'Tilbake til lærervisning',
  ComponentLearningElementLtiElement: 'Oppgåve',
  ComponentLearningElementSectionTagElement: 'Section Tag',
  ComponentLearningElementSectionEndTagElement: 'Section End',
  ComponentLearningElementArticleElement: 'Artikkel',
  ComponentLearningElementVideoElement: 'Video',
  ComponentLearningElementAudioElement: 'Song',
  ComponentLearningElementImageElement: 'Bilete',
  ComponentLearningElementImageElementSet: 'Biletserie',
  ComponentLearningElementDbokLinkElement: 'D-bok',
  ComponentLearningElementFileElement: 'Dokument',
  ComponentLearningElementTextBox: 'Text',
  ComponentLearningElementActivityElement: 'Caseoppgåver',
  ComponentLearningElementClickableArea: 'Myldrebilete',
  ComponentLearningElementTextElement: 'Text Element',
  ComponentLearningElementContentCarouselElement: 'Les og lytt',
  ComponentLearningElementConceptElement: 'Omgrep',
  ComponentLearningElementEmbedElement: 'Oppgåver',
  puls_ComponentLearningElementAudioElement: 'Audio',
  VideoLink: 'Video lenk',
  ExtLink: 'Lenk',
  ClickableArea: 'Tavlebilete',
  CCSet: 'Les og lytt',
  LessonPlan: 'Opplegg',
  LTISet: 'Oppgåver',
  DocBundle: 'Dokumenter',
  DIGITAL_CONCRETE: 'Digital konkret',
  CARD_LINK_TO: 'Gå til',
  CARD_LINK_TO_EXERCISES: 'Gå til oppgåver',
  CARD_LINK_TO_VIDEO: 'Gå til video',
  CARD_DOWNLOAD: 'Last ned:',
  BACK_BUTTON_LINK: 'Tilbake til',
  WEEK: 'uker',
  LESSON_HOUR: 'timer',
  LEVEL_SELECT_LABEL: 'Trinn',
  MINUTE: 'minutter',
  BUTTON_SEE_MORE: 'Sjå meir',
  CONCEPT_SEE_ALSO: 'Sjå òg',
  EXERCISE_NEXT: 'Neste',
  EXERCISE_PREVIOUS: 'Tidlegare',
  EXERCISE_INDICATOR: 'Indikator',
  EXERCISES_LIST_HEAD: 'Gå til oppgåve',
  LOGIN_HEADING: 'Velkommen til',
  LOGIN_SUBHEADING: 'Velkommen til',
  ORDRIKET_LOGIN_TEXT:
    'Ordriket, vårt prisvinnende norskverk for barnetrinnet, er nå ute i ny utgave!',
  ORDRIKET_LOGIN_FOOTER:
    'Gå til gamle <a href="https://ordriket.no">ordriket.no</a><br/><br/>Ønsker du å lære mer om Ordriket og hvordan du kan jobbe med de nye læreplanene i norsk? Denne nettressursen er gratis å bruke. Logg inn for å komme i gang!',
  LOGIN_LEARN_MORE: 'Hold meg oppdatert!',
  VOLUM_LOGIN_TEXT:
    'Matteverket Volum legger til rette for at elevene kan ta del i egen læringsprosess og utvikle nyttige problemløsningsstrategier i et lærende fellesskap.',
  VOLUM_LOGIN_FOOTER:
    'Ønsker du å lære mer om Volum og hvordan du kan jobbe med de nye læreplanene i matte? I løpet av høsten vil du også finne lærersider med undervisningsopplegg og mye annet innhold her.',
  LINK_LOGIN_FOOTER:
    'Ønsker du å lære mer om link og hvordan du kan jobbe med de nye læreplanene i engelsk? I løpet av høsten vil du også finne lærersider med undervisningsopplegg og mye annet innhold her.',
  LANG_CHANGE: 'Målform',
  LE_PAGES_NAV_NEXT: 'Neste',
  TEACHERS_UNIT_NO_RESULTS: 'No teachers content.',
  GOALS_WRAPPER_SHOW: 'VIS MER',
  GOALS_WRAPPER_HIDE: 'VIS MINDRE',
  COURSE_LEVELS: 'Pakker',
  COURSE_GO_TO_UNIT: 'Gå til pakke...',
  SYNOPSIS_NAV_OVERVIEW: 'Undervisningsopplegg',
  SYNOPSIS_NAV_TEACHER_HELP: 'Lærerhjelp',
  SYNOPSIS_NAV_BOOKS: 'Bøker',
  SYNOPSIS_NAV_HANDBOOK: 'Håndbok',
  SYNOPSIS_NAV_EXERCISEBOOK: 'Lesebok',
  SYNOPSIS_NAV_STUDENT: 'Elevsider',
  LOGIN_AS_STUDENT: 'Elever',
  LOGIN_AS_TEACHER: 'Lærere',
  CHOOSE_LEVEL_HEADING: 'Velg trinn',
  SWITCH_ROLE_LABEL: 'Vis:',
  SWITCH_ROLE_TEACHER: 'Lærar',
  SWITCH_ROLE_STUDENT: 'Elev',
  FILTER_RESET: 'Tilbakestill filtre',
  FILTER_LABEL: 'Filtrer etter',
  CARD_LABEL_LINK_ComponentLearningElementDbokLinkElement: 'Til Digital Bok',
  CARD_LABEL_LINK_ComponentLearningElementFileElement: 'Last ned',
  CARD_LABEL_LINK_ComponentLearningElementArticleElement: 'Til Nettsiden',
  CARD_LABEL_LINK_ComponentLearningElementConceptElement: 'Ekstern lenke',
  EmbedExercise: 'Oppgåver',
  CONCEPT_FILTER_DROPDOWN_BTN: 'Bruk filter',
  DURATION_MINUTES: 'minuter',
  BYGG_UNIT_LABEL: 'Del',
  CONCEPTS_SEARCH_NO_RESULTS: 'Ingen resultater',
  PLAN_NAME: 'Planlegging',
  LINK_LP_TEXT:
    '<i>link</i> er vårt nye engelskverk for hele barnetrinnet. Verket legger vekt på aktivitet, tverrfaglighet og interkulturell kompetanse.',
  LINK_LP_FOOTER_READ_MORE: 'BLI KJENT MED LINK',
  LINK_LP_FOOTER_LINK: 'https://www.fagbokforlaget.no/Verk/link',
  ORDRIKET_LP_TEXT: 'Velkommen til Ordriket.',
  ORDRIKET_LP_FOOTER_READ_MORE: 'BLI KJENT MED ORDRIKET',
  ORDRIKET_LP_FOOTER_LINK: 'https://www.fagbokforlaget.no/verk/ordriket',
  VOLUM_LP_TEXT: 'Volum er vårt nye matematikkverk for hele barnetrinnet.',
  VOLUM_LP_FOOTER_READ_MORE: 'BLI KJENT MED VOLUM',
  VOLUM_LP_FOOTER_LINK: 'https://www.fagbokforlaget.no/Verk/Volum',
  DBOK_MENU_TEXT:
    'D-bøker er digitale versjoner av de trykte bøkene. De er en del av de betalte pakkene Digital elev og Digital lærer.',
  DBOK_MENU_BTN_TEXT: 'Bestill prøvetilgang',
  LABEL_DOCUMENTATION_TOOL: 'Dokumentasjonverktøy',
  CONCRETES_CLOCK: 'Klokke',
  CONCRETES_DICES: 'Terninger',
  CONCRETES_GRID: 'Rutenett',
  CONCRETES_MONEY: 'Mynter og sedler',
  CONCRETES_NUMBERLINE: 'Tallinje',
  CONCRETES_PIECES: 'Brikker',
}

export default nn
