import auth from '@/auth'

const SET_ACCESS_TOKEN = (state) => {
  state.accessToken = auth.token
}

const SET_USER = (state, user) => {
  state.user = user
}

const LOGOUT_USER = (state) => {
  state.accesToken = null
  state.user = null
}

export default {
  SET_ACCESS_TOKEN,
  SET_USER,
  LOGOUT_USER
}
