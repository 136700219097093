const getAppLogo = state => {
  if (state.assets && state.assets.logo) {
    return state.assets.logo
  }

  return null
}

const getAppName = state => state.name
const getAppFamilyName = state => state.slug.split('-')[0]
const getAppLayoutOverview = state => state.layout && state.layout.overview
const getAppLayoutExercise = state => state.layout && state.layout.exercise
const getAppLayoutPlan = state => state.layout && state.layout.plan
const getAppLayoutNav = state => state.layout && state.layout.nav
const getAppLayoutTopBar = state => state.layout && state.layout.topbar
const getAppLayoutCard = state => state.layout && state.layout.card
const getAppLayoutUnit = state => state.layout && state.layout.unit
const getAppLayoutLesson = state => state.layout && state.layout.lesson
const getAppLayoutLETopbar = state => state.layout && state.layout.letopbar
const getAppSlug = state => state.slug
const getGTM = state => state.gtm
const getMeta = state => state.meta
const getAppId = state => state.id
const getAppLevels = state => {
  if (!state.level) return state.levels

  const withCurrentState = state.levels.map(level => {
    if (level.level === state.level) {
      level.current = true
    } else {
      level.current = false
    }

    return level
  })

  return withCurrentState
}
const getAppConceptsSettings = state => {
  const code = (state.currentLocale && state.currentLocale.code) || 'nb'

  return state.conceptsSettings && state.conceptsSettings[code]
}
const getAppText = state => {
  const code = (state.currentLocale && state.currentLocale.code) || 'nb'

  return state.text && state.text[code]
}
const getAppHasLogin = state => state.login || false
const getAppLoginType = state => state.login && state.login.type
const getAppLangs = state => state.langs
const hasLevels = state => state.levels.length
const hasTeacherAccess = state => state.access && state.access.teacher
const getTeacherAccessID = state =>
  (state.access && state.access.teacher) || null
const isTeacher = state => state.userRole === 'teacher'
const getAppMainNav = state => {
  const locale = (state.currentLocale && state.currentLocale.code) || 'nb'

  return (state.mainNav && state.mainNav[locale]) || []
}
const hasCheckedAccess = state => state.routerCheckedAccess
const hasRoleSwitch = state => state.hasRoleSwitch
const getDBok = state => state.dbok
const getDBokLink = state => state.dbokLink
const getAddons = state => state.addons

const getInactiveOverlay = state => {
  if (!state.inactive) return
  return typeof state.inactive === 'string'
    ? state.inactive
    : state.inactive[state.currentLocale?.code]
}

export default {
  getAppLogo,
  getAppId,
  getAppName,
  getAppText,
  getAppFamilyName,
  getAppSlug,
  getAppMainNav,
  getGTM,
  getMeta,
  getAppLayoutOverview,
  getAppLayoutExercise,
  getAppLayoutPlan,
  getAppLayoutNav,
  getAppLayoutTopBar,
  getAppLayoutCard,
  getAppLayoutUnit,
  getAppLayoutLesson,
  getAppLayoutLETopbar,
  getAppLevels,
  getAppLangs,
  getAppHasLogin,
  getAppLoginType,
  getAppConceptsSettings,
  getTeacherAccessID,
  hasTeacherAccess,
  isTeacher,
  hasLevels,
  hasCheckedAccess,
  hasRoleSwitch,
  getDBok,
  getDBokLink,
  getAddons,
  getInactiveOverlay,
}
