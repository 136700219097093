import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  name: '',
  id: '',
  slug: '',
  level: null,
  assets: null,
  layout: null,
  levels: [],
  addons: null,
  teacherAddons: null,
  userRole: 'student',
  gtm: '',
  beta: null,
  meta: [],
  currentLocale: null,
  langs: [],
  login: null,
  text: null,
  hasRoleSwitch: false,
  mainNav: null,
  conceptsSettings: null,
  access: null,
  routerCheckedAccess: false,
  dbok: null,
  inactive: null,
  dbokLink: null
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
