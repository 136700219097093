import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  course: null,
  units: [],
  lessons: []
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
