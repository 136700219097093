import LoginBase from './base'
class LoginTeacher extends LoginBase {
  constructor() {
    super()
  }

  routeHandler({ to, next }) {
    if (!this.store.getters['appConfig/hasCheckedAccess']) {
      this.auth.checkToken(to.query.token ? `?token=${to.query.token}` : '')
        .then(user => {
          if (to.query.token) {
            delete to.query.token
          }

          /* remove when we move to checkAccess */
          this.store.dispatch('user/setToken')
          this.store.dispatch('user/setUser', user)
          this.store.dispatch('appConfig/setUserRole', 'teacher')
          this.store.dispatch('appConfig/toggleCheckedAccess', true)

          next({ path: to.path, query: to.query })
          /* endremove */

          /* for now all users can have access to teacher page after login */
          // this.auth.checkAccess(this.store.getters['appConfig/getTeacherAccessID'])
          //   .then(res => {
          //     if (res.products.some(product => product.id === this.store.getters['appConfig/getTeacherAccessID'])) {
          //       this.store.dispatch('user/setToken')
          //       this.store.dispatch('user/setUser', user)
          //       this.store.dispatch('appConfig/setUserRole', 'teacher')
          //       this.store.dispatch('appConfig/toggleCheckedAccess', true)

          //       next({ path: to.path, query: to.query })
          //     } else {
          //       next({ path: '/login' })
          //     }
          //   })
        })
        .catch(() => {
          next({path: '/login'})
        })
    } else {
      next()
    }
  }

  handleTeacher() {
    if (this.store.getters['appConfig/getAppLevels'].length > 1) {
      this.auth.authorize({
        'redirectUrl': window.location.origin + '/'
      })
    } else {
      if (this.store.getters['appConfig/getAppLevels'][0]) {
        this.auth.authorize({
          'redirectUrl': this.store.getters['appConfig/getAppLevels'][0].url
        })
      } else {
        this.auth.authorize({
          'redirectUrl': window.location.origin
        })
      }
    }
  }
}

export default () => new LoginTeacher()
