const getCourse = state => state.course
const getUnits = state => state.units
const getLessons = state => state.lessons
const getLessonUnits = state => lessonId => {
  const matchedUnits = []

  state.units.forEach(unit => {
    if (unit.orderedLessons) {
      unit.orderedLessons.forEach(ol => {
        if (ol.lesson === lessonId) {
          matchedUnits.push(unit)
        }      
      })
    }
  })

  return matchedUnits
}
const getUnitLessons = (state, getters) => unitId => {
  const unit = state.units.filter(unit => unit.id === unitId)[0]
  const lessons = getters.getLessons.filter(sl => unit.orderedLessons.filter(ol => ol.lesson === sl.id).length > 0)

  return lessons
}
const getUnitBySlug = state => slug => {
  const content = state.units.filter(unit => unit.slug === slug)[0]

  return content
}
const getLessonById = state => id => {
  const lesson = state.lessons.filter(lesson => lesson.id === id)

  return lesson[0]
}
const getLessonBySlug = state => slug => {
  const lesson = state.lessons.filter(lesson => (lesson.slug === slug || lesson.id === slug))

  return lesson[0]
}
const hasStoredUnitLessons = (state, getters) => slug => {
  const unit = state.units.filter(unit => unit.slug === slug)[0]
  const olLength = unit.orderedLessons.length
  let storedLessonsCount = 0

  getters.getLessons.forEach(sl => {
    if (unit.orderedLessons.filter(ol => ol.lesson === sl.id).length > 0) storedLessonsCount += 1
  })

  return olLength === storedLessonsCount
}

export default {
  getCourse,
  getUnits,
  getLessons,
  getLessonUnits,
  getUnitLessons,
  getUnitBySlug,
  getLessonById,
  getLessonBySlug,
  hasStoredUnitLessons
}
