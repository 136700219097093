import LoginBase from './base'

class LoginTeacher extends LoginBase {
  constructor() {
    super()
  }

  routeHandler({ to, next, config }) {
    // Force login page for apps without role switcher
    if (!config.hasRoleSwitch) {
      this.withoutRoleSwitch(to, next)
    } else {
      this.withRoleSwitch(to, next)
    }
  }

  withoutRoleSwitch(to, next) {
    if (!this.store.getters['appConfig/hasCheckedAccess']) {
      this.auth.checkToken(to.query.token ? `?token=${to.query.token}` : '')
        .then(user => {
          this.store.dispatch('user/setToken')
          this.store.dispatch('user/setUser', user)
          if (!this.store.getters['appConfig/hasTeacherAccess']) {
            this.store.dispatch('appConfig/setUserRole', 'teacher')
          }
          this.store.dispatch('appConfig/toggleCheckedAccess', true)
          if (to.query.token) {
            delete to.query.token
          }
          next({ path: to.path, query: to.query })
        })
        .catch(() => {
          next('/login')
        })
    } else {
      next()
    }
  }

  withRoleSwitch(to, next) {
    if (!this.store.getters['appConfig/hasCheckedAccess']) {
      this.auth.checkToken(to.query.token ? `?token=${to.query.token}` : '')
        .then(user => {
          this.store.dispatch('user/setToken')
          this.store.dispatch('user/setUser', user)
          if (!this.store.getters['appConfig/hasTeacherAccess']) {
            this.store.dispatch('appConfig/setUserRole', localStorage.getItem('pickedUserRole') || 'teacher')
          }
          this.store.dispatch('appConfig/toggleCheckedAccess', true)
          if (to.query.token) {
            delete to.query.token
          }
          next({ path: to.path, query: to.query })
        })
        .catch(() => {
          next()
        })
    } else {
      next()
    }
  }

  handleStudent() {
    this.router.push('/')
    this.store.dispatch('user/setUser', {})
    this.store.dispatch('appConfig/toggleCheckedAccess', true)
  }

  handleTeacher() {
    if (this.store.getters['appConfig/getAppLevels'].length > 1) {
      this.auth.authorize({
        'redirectUrl': window.location.origin + '/'
      })
    } else {
      if (this.store.getters['appConfig/getAppLevels'][0]) {
        this.auth.authorize({
          'redirectUrl': this.store.getters['appConfig/getAppLevels'][0].url
        })
      } else {
        this.auth.authorize({
          'redirectUrl': window.location.origin
        })
      }
    }
  }
}

export default () => new LoginTeacher()
