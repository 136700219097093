<template>
  <div class="c-switch-role__wrapper">
    <span class="c-switch-role__switch-label">
      {{ $t('SWITCH_ROLE_LABEL') }}
    </span>

    <input
      class="c-switch-role__hide-visually"
      type="radio"
      id="switch-role-teacher"
      name="role-switch"
      value="teacher" 
      :checked="isTeacher" 
      @change="switchRole()"
    >
    <label
      class="c-switch-role__input-label"
      :class="{ 'c-switch-role__input-label--active': isTeacher }"
      for="switch-role-teacher"
    >
      {{ $t('SWITCH_ROLE_TEACHER') }}
    </label>
    <TheButton
      class="c-switch-role__toggler-btn"
      @click.native="switchRole()"
    >
      <span
        class="c-switch-role__ball"
        :class="{ 'c-switch-role__ball--left': isTeacher }"
      ></span>
    </TheButton>
    <input
      class="c-switch-role__hide-visually"
      type="radio"
      id="switch-role-student"
      name="role-switch"
      value="student"
      :checked="!isTeacher"
      @change="switchRole()"
    >
    <label
      class="c-switch-role__input-label"
      :class="{ 'c-switch-role__input-label--active': !isTeacher }"
      for="switch-role-student"
    >
        {{ $t('SWITCH_ROLE_STUDENT') }}
      </label>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import TheButton from '@forlagshuset/v-button'
import auth from '@/auth'

export default {
  components: {
    TheButton
  },

  setup(props, { root }) {
    const isTeacher = computed(() => root.$store.getters['appConfig/isTeacher'])
    const hasUser = computed(() => root.$store.getters['user/hasUser'])
    const hasCheckRole = ref(false)

    const switchRole = () => {
      if (!hasCheckRole.value && !hasUser.value) {
        auth.checkToken(root.$route.query.token ? `?token=${root.$route.query.token}` : '')
          .then(user => {
            root.$store.dispatch('user/setToken')
            root.$store.dispatch('user/setUser', user)
            hasCheckRole.value = true
          })
          .catch(() => {
            auth.authorize({
              'redirectUrl': window.hostname
            })
        })
      }

      if (hasCheckRole) {
        localStorage.setItem('pickedUserRole', isTeacher.value ? 'student' : 'teacher')
        root.$store.dispatch('appConfig/setUserRole', isTeacher.value ? 'student' : 'teacher')
      }
    }

    return {
      isTeacher,
      switchRole
    }
  }
}
</script>