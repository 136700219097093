<template>
  <div class="c-footer">
    <FHFooter class="c-fh-footer" hide-social />
  </div>
</template>

<script>
import FHFooter from '@forlagshuset/v-small-footer'

export default {
  components: {
    FHFooter
  }
}
</script>
