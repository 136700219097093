import LoginBase from './base'

class LoginBundle extends LoginBase {
  constructor() {
    super()
  }

  routeHandler({ to, next }) {
    this.auth.checkToken(to.query.token ? `?token=${to.query.token}` : '')
      .then(user => {
        this.store.dispatch('user/setToken')
        this.store.dispatch('user/setUser', user)

        next('/')
      })
      .catch(() => {
        next({path: '/login'})
      })
  }

  handleStudent() {
    window.location = this.store.getters['appConfig/getAppLevels'][0].url
  }

  handleTeacher() {
    if (this.store.getters['appConfig/getAppLevels'][0]) {
      this.auth.authorize({
        'redirectUrl': this.store.getters['appConfig/getAppLevels'][0].url
      })
    } else {
      this.auth.authorize({
        'redirectUrl': window.location.origin
      })
    }
  }
}

export default () => new LoginBundle()
