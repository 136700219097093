const SET_COURSE = (state, course) => {
  state.course = course
}

const SET_UNITS = (state, units) => {
  state.units = units
}

const SET_UNIT = (state, unit) => {
  state.units.push(unit)
}

const SET_LESSON = (state, lesson) => {
  state.lessons.push(lesson)
}

export default {
  SET_COURSE,
  SET_UNITS,
  SET_UNIT,
  SET_LESSON
}
