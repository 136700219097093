/* eslint-disable no-console */
import showdown from 'showdown'

const markdownToHtml = md => {
  const converter = new showdown.Converter()
  converter.setOption('tables', true)
  converter.setOption('backslashEscapesHTMLTags', true)

  return converter.makeHtml(md)
}

const addCloudinaryUrlParams = (url, param) => {
  let newURL
  const splitURL = url.split('upload/')

  newURL = `${splitURL[0]}upload/${param}/${splitURL[1]}`

  return newURL
}

const hasComponent = componentName => mappedData[componentName] !== undefined

const parseLearningElements = (resources) => {
  let inSection = false
  let sectionItem = null
  const parsedLearningElements = []

  resources.forEach(element => {
    if(componentNameNormalize(element.__component) === 'ComponentLearningElementSectionTagElement') {
      inSection = true
      sectionItem = element
      sectionItem.items = []
      sectionItem.properties.forEach(prop => {
        const { key, value } = prop

        if (key === 'type') {
          sectionItem.__component = value.replace(/\s/g,'')
        } else {
          sectionItem[key] = value.replace(/\s/g,'')
        }
      })
    } else if (componentNameNormalize(element.__component) === 'ComponentLearningElementSectionEndTagElement') {
      inSection = false
      parsedLearningElements.push(sectionItem)
      sectionItem = null
    } else {
      // Skip not supported components and throw error
      if (!hasComponent(componentNameNormalize(element.__component))) {
        console.warn(`${componentNameNormalize(element.__component)} is not supported.`)

        return
      }

      if (componentNameNormalize(element.__component) === 'ComponentLearningElementTextBox') {
        // Quick fix before deploy, but we should change IT!! Bygg links page <
        const validate = ['ExtLink', 'VideoLink']

        if (element.properties.some(prop => validate.some(v => v === prop.value))) {
          //external links
          element.__component = element.properties[0].value
        }
      }

      if (inSection) {
        sectionItem.items.push(element)

        return
      }

      parsedLearningElements.push(element)
    }
  })

  return parsedLearningElements
}

const mappedData = {
  ExtLink: {
    id: item => item.id,
    title: () => '',
    description: item => item.content,
    link: (id,lesson,item) => item.properties && item.properties[1].value,
    target: '_blank',
    image: item => item && item.image_type || null
  },
  VideoLink: {
    id: item => item.id,
    title: () => '',
    description: item => item.content,
    link: (id,lesson,item) => item.properties && item.properties[1].value,
    target: '_blank',
    image: item => item && item.image_type || null
  },
  DocBundle: {
    id: item => item.id,
    title: (item) => item.name,
    description: item => item.description,
    link: (id,lesson) => `/exercises/${lesson}/${id}`,
    image: item => item && item.image_type || null
  },
  LTISet: {
    id: item => item.id,
    title: (item) => item.name,
    description: item => item.description,
    link: (id,lesson) => `/exercises/${lesson}/${id}`,
    image: item => item && item.image_type || null
  },
  CCSet: {
    id: item => item.id,
    title: (item) => item.name,
    description: item => item.description,
    link: (id, lesson) => `/content/${lesson}/${id}`,
    image: item => item.image_type ? item.image_type : item.items[0].image
  },
  LessonPlan: {
    id: item => item.id,
    title: (item) => item.name,
    description: item => item.description,
    link: (id, lesson) => `/lesson/${lesson}`,
    image: item => item && item.image_type || null
  },
  ComponentLearningElementArticleElement: {
    id: item => item.id,
    title: (item) => item.name,
    description: item => item.description,
    link: (id, lesson) => `/article/${lesson}/${id}`,
    image: item => item && item.image_type || null
  },
  ComponentLearningElementLtiElement: {
    id: item => item.id,
    title: (item) => item.contextTitle,
    description: item => item.instructions,
    link: (id, lesson) => `/exercise/${lesson}/${id}`,
    image: item => item && item.image_type || null
  },
  ComponentLearningElementSectionTagElement: {
    id: item => item.id,
    title: () => '',
    description: item => item.description,
    link: () => {},
    image: item => item && item.image_type || null
  },
  ComponentLearningElementSectionEndTagElement: {
    id: item => item.id,
    title: () => '',
    description: '',
    link: () => {},
    image: item => item && item.image_type || null
  },
  ComponentLearningElementTextBox: {
    id: item => item.id,
    title: () => '',
    description: item => item.content,
    link: () => {},
    image: item => item && item.image_type || null
  },
  ComponentLearningElementTextElement: {
    id: item => item.id,
    title: item => item.title,
    description: item => item.body,
    link: () => {},
    image: item => item && item.image_type || null
  },
  ComponentLearningElementVideoElement: {
    id: item => item.id,
    title: (item) => item.video_type ? (item.video_type.name || item.name) : '',
    description: item => item.video_type ? item.video_type.videoText: '',
    link: (id, lesson, item) => item && item.video_type && item.video_type.file ? `/video/${lesson}/${id}` : '',
    image: item => item && item.video_type && item.video_type.cover && item.video_type.cover.image_type || null
  },
  ComponentLearningElementDbokLinkElement: {
    id: item => item.bookId,
    title: (item) => item.bookTitle, //getDBokTitle from API
    description: item => item.description,
    link: (id, lesson, item) => `https://dur.dbok.no/#/book/${id}/${item.startPage}`,
    target: '_blank',
    image: item => item && item.image_type || null
  },
  ComponentLearningElementFileElement: {
    id: item => item.id,
    title: (item) => item.file_type ? item.file_type.name : '',
    description: item => item.file_type ? item.file_type.description : '',
    link: (id, lesson, item) => item.file_type?.file?.url || '',
    file: item => item.file_type?.file,
    target: '_blank',
    image: item => item && item.image_type || null
  },
  ComponentLearningElementImageElement: {
    id: item => item.id,
    title: (item) => item.image_type ? item.image_type.name: '',
    description: item => item.image_type ? item.image_type.description : '',
    link: (id, lesson) => `/image/${lesson}/${id}`,
    image: item => item && item.image_type || null
  },
  ComponentLearningElementImageElementSet: {
    id: item => item.id,
    title: item => item && item.name || '',
    description: item => item.description,
    link: (id, lesson) => `/images/${lesson}/${id}`,
    image: item => item && item.images.length && item.images[0].image_type || null
  },
  ComponentLearningElementAudioElement: {
    id: item => item.audio_type && item.audio_type.id,
    title: (item) => item.audio_type ? item.audio_type.name : '',
    description: item => item.audio_type ? item.audio_type.description : '',
    link: (id, lesson) => `/audio/${lesson}/${id}`,
    image: item => item && item.image_type || null
  },
  ComponentLearningElementActivityElement: {
    id: item => item.activity_type.slug,
    title: (item) => item.activity_type ? item.activity_type.name : '',
    description: item => item.description,
    link: (id, lesson) => `/activity/${lesson}/${id}`,
    image: item => item && item.activity_type && item.activity_type.cover && item.activity_type.cover.image_type || null
  },
  ComponentLearningElementClickableArea: {
    id: item => item.id,
    title: (item) => item.name,
    description: item => item.description,
    link: (id, lesson) => `/clickable-area/${lesson}/${id}`,
    image: item => item && item.image_type || null
  },
  ComponentLearningElementContentCarouselElement: {
    id: item => item.id,
    title: (item) => item.name,
    description: item => item.description,
    link: (id, lesson) => `/content/${lesson}/${id}`,
    image: item => item && item.image || null
  },
  ComponentLearningElementConceptElement : {
    id: item => item.id,
    title: (item) => item.name,
    description: item => item.concept_type ? item.concept_type.description : '',
    link: (id, lesson, item) => `https://synopsis.fagbokforlaget.no/?concept=${item.concept_type.slug}`,
    target: '_blank',
    image: item => item && item.image_type || null
  },
  ComponentLearningElementEmbedElement: {
    id: item => item.id,
    title: item => item.name,
    description: item => item.embedId,
    link: (id, lesson) => `/embed/${lesson}/${id}`,
    image: item => item && item.image_type || null
  }
}

const componentNameNormalize = name => {
  if (name.indexOf('-') === -1 || name.indexOf('.') === -1) return name

  const splitDots = name.split('.')
  const removedDots = splitDots.map(string => {
    return string[0].toUpperCase() + string.slice(1)
  }).join('')
  const splitHyphens = removedDots.split('-')
  const removedHyphensAndDots = splitHyphens.map(string => {
    return string[0].toUpperCase() + string.slice(1)
  }).join('')
  const modify = `Component${removedHyphensAndDots}`

  return modify
}

const mergeUnits = units => {
  const list = []
  //gather parents
  units.forEach((unit, index) => {
    if(!unit.parent){
      unit.units = []
      list.push(unit)
    }
    if(index === units.length-1) {
      //insert children
      list.map(parent => {
        parent.units = units.filter(unit => unit.parent ? unit.parent.id === parent.id : '')

        return parent
      })
    }
  })

  return list
}

const isSecureLink = () => `http${process.env.NODE_ENV !== 'local' ? 's' : '' }`

const hmsToSeconds = string => {
  string = string.split('.')[0]
  const p = string.split(':')
  let s = 0
  let m = 1

  while (p.length > 0) {
      s += m * parseInt(p.pop(), 10)
      m *= 60
  }

  return s
}

export {
  hmsToSeconds,
  hasComponent,
  componentNameNormalize,
  addCloudinaryUrlParams,
  markdownToHtml,
  parseLearningElements,
  mappedData,
  mergeUnits,
  isSecureLink
}